import { MockModel } from "../_models/MockModel";

export const MOCK_DATA: MockModel = {
  healthcareid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/HealthcareService/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/HealthcareService/id",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-HealthcareService",
    path: [
      {
        id: 1,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 2,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 3,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 4,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  healthcare: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/HealthcareService",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/HealthcareService",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-HealthcareService",
    path: [],
    header: [
      {
        id: 5,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 6,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 7,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 8,
        label: "coverage-area",
        placeholder: "Enter coverage area",
        value: "", // Provide the initial value here
        info: "Location(s) service is intended for/available to.",
        infobold: "",
        chains: true,
        chipsname: ["address", "address-postalcode", "address-city", "address-state", "type", "organization.name"],
      },
      {
        id: 9,
        label: "endpoint",
        placeholder: "Enter endpoint",
        value: "", // Provide the initial value here
        info: "Technical endpoints providing access to electronic services operated for the healthcare service.",
        infobold: "",
        chains: true,
        chipsname: ["organization.name"],
      },
      {
        id: 10,
        label: "location",
        placeholder: "Enter location",
        value: "", // Provide the initial value here
        info: "The location of the Healthcare Service.",
        infobold: "",
        chains: true,
        chipsname: ["address", "address-postalcode", "address-city", "address-state", "type", "organization.name"],
      },
      {
        id: 1110,
        label: "name",
        placeholder: "Enter name",
        value: "", // Provide the initial value here
        info: "A portion of the healthcare service name.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 1111,
        label: "Organization",
        placeholder: "Enter Organization",
        value: "", // Provide the initial value here
        info: "The organization that provides this Healthcare Service.",
        infobold: "",
        chains: true,
        chipsname: ["name", "address", "type", 'partof.name'],
      },
      {
        id: 11,
        label: "service-category",
        placeholder: "Enter service-category",
        value: "", // Provide the initial value here
        info: "Service Category of the Healthcare Service.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 12,
        label: "service-type",
        placeholder: "Enter service-type",
        value: "", // Provide the initial value here
        info: "The type of service provided by this healthcare service.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 13,
        label: "specialty",
        placeholder: "Enter specialty",
        value: "", // Provide the initial value here
        info: "The specialty of the service provided by this healthcare service.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  endpoint: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Endpoint",
    codeurl:
      " https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Endpoint",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Endpoint",
    path: [],
    header: [
      {
        id: 14,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
      {
        id: 15,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 16,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: ["organization.name"],
      },
      {
        id: 17,
        label: "organization",
        placeholder: "Enter organization",
        value: "",
        info: "Select Endpoints managed by the specified organization.",
        infobold: "",
        chains: true,
        chipsname: ["name", "address", "type", "partof.name"],
      },
    ],
    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
    ],
  },
  endpointid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Endpoint/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Endpoint/id",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Endpoint",
    path: [
      {
        id: 18,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 19,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 20,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 21,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  insurance: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/InsurancePlan",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/InsurancePlan",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-InsurancePlan",
    path: [],
    header: [
      {
        id: 22,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 23,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],
    queryParams: [
      {
        id: 24,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 25,
        label: "administered-by",
        placeholder: "Enter administered by",
        value: "", // Provide the initial value here
        info: "Select products that are administered by the specified organization.",
        infobold: "",
        chains: true,
        chipsname: ["name", "partof.name"],
      },
      {
        id: 1224,
        label: "identifier",
        placeholder: "Enter identifier",
        value: "", // Provide the initial value here
        info: "Select products with the specified identifier.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 1234,
        label: "name",
        placeholder: "Enter name",
        value: "", // Provide the initial value here
        info: "Select products with the specified name.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 225,
        label: "owned-by",
        placeholder: "Enter owned-by",
        value: "", // Provide the initial value here
        info: "Select products that are owned by the specified organization.",
        infobold: "",
        chains: true,
        chipsname: ["name", "partof.name"],
      },
      {
        id: 27,
        label: "plan-type",
        placeholder: "Enter plan-type",
        value: "", // Provide the initial value here
        info: "Select plans of the specified type.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 28,
        label: "type",
        placeholder: "Enter type",
        value: "", // Provide the initial value here
        info: "Select insurance plans of the specified type.",
        infobold: "",
        chains: false,
        chipsname: [],
      }
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  insuranceid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/InsurancePlan/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/InsurancePlan/id",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-InsurancePlan",
    path: [
      {
        id: 30,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 31,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 32,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 33,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  location: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Location",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Location",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Location",
    path: [],
    header: [
      {
        id: 34,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 35,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 36,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 37,
        label: "address",
        placeholder: "Address",
        value: "",
        info: "Select Locations with the specified ",
        infobold: "address",
        chains: false,
        chipsname: ["organization.name"],
      },
      {
        id: 38,
        label: "address-city",
        placeholder: "City",
        value: "",
        info: "Select Locations with the specified ",
        infobold: "address.city",
        chains: false,
        chipsname: ["organization.name"],
      },
      {
        id: 39,
        label: "address-postalcode",
        placeholder: "Postalcode",
        value: "",
        info: "Select Locations with the specified ",
        infobold: "address.postalcode",
        chains: false,
        chipsname: ["organization.name"],
      },
      {
        id: 40,
        label: "address-state",
        placeholder: "State",
        value: "",
        info: "Select Locations with the specified ",
        infobold: "address.state",
        chains: false,
        chipsname: ["organization.name"],
      },
      {
        id: 41,
        label: "endpoint",
        placeholder: "Endpoint",
        value: "",
        info: "Select Locations with the specified endpoint.",
        infobold: "",
        chains: true,
        chipsname: ["organization.name"],
      },
      {
        id: 241,
        label: "organization",
        placeholder: "Enter organization",
        value: "",
        info: "Select Locations managed by the specified organization.",
        infobold: "",
        chains: true,
        chipsname: ["name", "address", "type", "partof.name"],
      },
      {
        id: 242,
        label: "partof",
        placeholder: "Enter partof",
        value: "",
        info: "Select Locations that are part of the specified location.",
        infobold: "",
        chains: true,
        chipsname: ["address", "type", "organization.name"],
      },
      {
        id: 366,
        label: "type",
        placeholder: "Enter type",
        value: "", // Provide the initial value here
        info: "Select Locations of the specified type.",
        infobold: "",
        chains: false,
        chipsname: [],
      }
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  locationid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Location/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Location/id",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Location",
    path: [
      {
        id: 42,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 43,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 44,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 45,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  network: {
    url: "https://api-sandbox.aaneelconnect.com/providerdirectory/Network",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/providerdirectory/Network",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Network",
    path: [],
    header: [
      {
        id: 46,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 47,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 48,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 49,
        label: "address",
        placeholder: "Address",
        value: "",
        info: "Select network with the specified address (matches any of the string elements of an address)",
        infobold: "address",
        chains: false,
        chipsname: [],
      },
      {
        id: 50,
        label: "coverage-area",
        placeholder: "coverage-area",
        value: "",
        info: "Select health insurance provider networks available in a region described by the specified location",
        infobold: "",
        chains: true,
        chipsname: ["address", "type", "organization.name"],
      },
      {
        id: 51,
        label: "endpoint",
        placeholder: "Enter endpoint",
        value: "", // Provide the initial value here
        info: "Select networks with the specified endpoint.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  networkid: {
    url: "https://api-sandbox.aaneelconnect.com/providerdirectory/Network/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/providerdirectory/Network/id",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Network",
    path: [
      {
        id: 52,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 53,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 54,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 55,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  organization: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Organization",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Organization",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Organization",
    path: [],
    header: [
      {
        id: 56,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 57,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 58,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 59,
        label: "address",
        placeholder: "Address",
        value: "",
        info: "Select organizations with the specified address",
        infobold: "",
        chains: false,
        chipsname: ["organization.name"],
      },
      {
        id: 60,
        label: "address-city",
        placeholder: "City",
        value: "",
        info: "A city specified in an address ",
        infobold: "",
        chains: false,
        chipsname: ["organization.name"],
      },
      {
        id: 260,
        label: "address-country",
        placeholder: "country",
        value: "",
        info: "A country specified in an address ",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 61,
        label: "address-postalcode",
        placeholder: "Postalcode",
        value: "",
        info: "A postal code specified in an address",
        infobold: "",
        chains: false,
        chipsname: ["organization.name"],
      },
      {
        id: 62,
        label: "address-state",
        placeholder: "State",
        value: "",
        info: "A state specified in an address",
        infobold: "",
        chains: false,
        chipsname: ["organization.name"],
      }, {
        id: 262,
        label: "address-use",
        placeholder: "use",
        value: "",
        info: "A use code specified in an address",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 63,
        label: "endpoint",
        placeholder: "Endpoint",
        value: "",
        info: "Select Organizations with the specified endpoint",
        infobold: "",
        chains: true,
        chipsname: ["organization.name"],
      }, {
        id: 362,
        label: "name",
        placeholder: "name",
        value: "",
        info: "Select Organizations with the specified name",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 64,
        label: "partof",
        placeholder: "partof",
        value: "",
        info: "Select Organizations that are part of the specified organization.",
        infobold: "",
        chains: true,
        chipsname: ["name", "address", "type"],
      },
      {
        id: 266,
        label: "type",
        placeholder: "Enter type",
        value: "", // Provide the initial value here
        info: "Select Organizations of the specified type.",
        infobold: "",
        chains: false,
        chipsname: [],
      }
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  organizationid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Organization/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Organization/id",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Organization",
    path: [
      {
        id: 65,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 66,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 67,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 68,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  list: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/List",
    codeurl: "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/List",
    languageurl:
      "https://hl7.org/fhir/us/Davinci-drug-formulary/StructureDefinition-usdf-CoveragePlan.html",
    path: [],
    header: [
      {
        id: 69,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
      {
        id: 70,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 71,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: ["organization.name"],
      }
    ],
    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
    ],
  },
  listid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/List/{id}",
    codeurl: "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/List/id",
    languageurl:
      "https://hl7.org/fhir/us/Davinci-drug-formulary/StructureDefinition-usdf-CoveragePlan.html",
    path: [
      {
        id: 73,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 74,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 75,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 76,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  substance: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/Substance",
    codeurl:
      " https://api-sandbox.aaneelconnect.com/cms/r4/formulary/Substance",
    languageurl: "http://hl7.org/fhir/StructureDefinition/Substance",
    path: [],
    header: [
      {
        id: 77,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 78,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 79,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 80,
        label: "category",
        placeholder: "category",
        value: "",
        info: "The category of the substance",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 81,
        label: "code",
        placeholder: "code",
        value: "",
        info: "The code of the substance or ingredient",
        infobold: "",
        chains: false,
        chipsname: [],
      }
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  substanceid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/Substance/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/Substance/id",
    languageurl: "http://hl7.org/fhir/StructureDefinition/Substance",
    path: [
      {
        id: 85,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 86,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 87,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 88,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  medicationknowledge: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/Medicationknowledge",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/Medicationknowledge",
    languageurl:
      "https://hl7.org/fhir/us/Davinci-drug-formulary/StructureDefinition-usdf-FormularyDrug.html",
    path: [],
    header: [
      {
        id: 89,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 90,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 91,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Change Healthcare Payer ID. Please read API marketplace documentation page for required parameters.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 92,
        label: "Drug Name",
        placeholder: "Enter drug name",
        value: "", // Provide the initial value here
        info: "Access the descriptive name of a formulary drug.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 93,
        label: "Drug Plan",
        placeholder: "Enter drug plan",
        value: "", // Provide the initial value here
        info: "Access the drug plan id of a formulary drug.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 94,
        label: "Drug Tier",
        placeholder: "Enter drug tier",
        value: "", // Provide the initial value here
        info: "Access the drug tier id of a formulary drug.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 97,
        label: "Code",
        placeholder: "Enter code",
        value: "", // Provide the initial value here
        info: "Code that identifies this medication.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  medicationknowledgeid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/MedicationKnowledge/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/formulary/MedicationKnowledge/id",
    languageurl:
      "https://hl7.org/fhir/us/Davinci-drug-formulary/StructureDefinition-usdf-FormularyDrug.html",
    path: [
      {
        id: 98,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 99,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 100,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 101,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  OrganizationAffiliation: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/OrganizationAffiliation",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/OrganizationAffiliation",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-OrganizationAffiliation",
    path: [],
    header: [
      {
        id: 102,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 103,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 104,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 106,
        label: "endpoint",
        placeholder: "Enter endpoint",
        value: "", // Provide the initial value here
        info: "Select OrganizationAffiliations with the specified endpoint.",
        infobold: "",
        chains: true,
        chipsname: ["organization.name"],
      },
      {
        id: 105,
        label: "location",
        placeholder: "Enter location",
        value: "", // Provide the initial value here
        info: "Select OrganizationAffiliations available at the specified location.",
        infobold: "",
        chains: true,
        chipsname: ["address", "address-postalcode", "address-city", "address-state", "type", "organization.name",
          "organization.address",
          "organization.partof.name",
          "organization.type"],
      },
      {
        id: 405,
        label: "network",
        placeholder: "Enter network",
        value: "", // Provide the initial value here
        info: "Select roles where the organization is a member of the specified health insurance provider network.",
        infobold: "",
        chains: true,
        chipsname: ["name", "partof.name"],
      },
      {
        id: 107,
        label: "participating-organization",
        placeholder: "Enter participating-organization",
        value: "", // Provide the initial value here
        info: "Select roles filled by the specified organization",
        infobold: "",
        chains: true,
        chipsname: ["address", "name", "type", "partof.name"],
      },
      {
        id: 407,
        label: "primary-organization",
        placeholder: "Enter primary-organization",
        value: "", // Provide the initial value here
        info: "Select roles offered by the specified organization.",
        infobold: "",
        chains: true,
        chipsname: ["address", "name", "type", "partof.name"],
      }, {
        id: 404,
        label: "role",
        placeholder: "Enter role",
        value: "", // Provide the initial value here
        info: "Select OrganizationAffiliations with the specified role.",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 507,
        label: "service",
        placeholder: "Enter service",
        value: "", // Provide the initial value here
        info: "Select OrganizationAffiliations providing the specified service.",
        infobold: "",
        chains: true,
        chipsname: ["service-category", "organization.name", "location.name"],
      }, {
        id: 204,
        label: "specialty",
        placeholder: "Enter specialty",
        value: "", // Provide the initial value here
        info: "Select OrganizationAffiliations associated with the specified specialty.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  OrganizationAffiliationid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/OrganizationAffiliation/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/OrganizationAffiliation/id",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-OrganizationAffiliation",
    path: [
      {
        id: 108,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 109,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 110,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 111,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Practitioner: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Practitioner",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Practitioner",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Practitioner",
    path: [],
    header: [
      {
        id: 112,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 113,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 114,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 115,
        label: "family",
        placeholder: "Enter family",
        value: "", // Provide the initial value here
        info: "The practitioner's family name.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 116,
        label: "given",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "The practitioner's given name.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 117,
        label: "name",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "The practitioner's name.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Practitionerid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Practitioner/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/Practitioner/id",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Practitioner",
    path: [
      {
        id: 118,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 119,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 120,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 121,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  PractitionerRole: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/PractitionerRole",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/PractitionerRole",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-PractitionerRole",
    path: [],
    header: [
      {
        id: 122,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 123,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 124,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 125,
        label: "endpoint",
        placeholder: "Enter endpoint",
        value: "", // Provide the initial value here
        info: "Technical endpoints providing access to electronic services operated for the healthcare service.",
        infobold: "",
        chains: true,
        chipsname: ["organization.name"],
      },
      {
        id: 126,
        label: "location",
        placeholder: "Enter location",
        value: "", // Provide the initial value here
        info: "The location of the Healthcare Service.",
        infobold: "",
        chains: true,
        chipsname: ["address", "address-postalcode", "address-city", "address-state", "type", "organization.name",
          "organization.address",
          "organization.partof.name",
          "organization.type"],
      },
      {
        id: 127,
        label: "network",
        placeholder: "Enter network",
        value: "", // Provide the initial value here
        info: "Select roles where the practitioner is a member of the specified health insurance provider network.",
        infobold: "",
        chains: true,
        chipsname: ["name", "address", "type", "partof.name"],
      },
      {
        id: 128,
        label: "organization",
        placeholder: "Enter organization",
        value: "", // Provide the initial value here
        info: "Organization where the roles are available.",
        infobold: "",
        chains: true,
        chipsname: ["name", "address", "type", "partof.name"],
      },
      {
        id: 129,
        label: "practitioner",
        placeholder: "Enter practitioner",
        value: "", // Provide the initial value here
        info: "Practitioner that is able to provide the defined services for the organization.",
        infobold: "",
        chains: true,
        chipsname: ["name"],
      },
      {
        id: 224,
        label: "role",
        placeholder: "Enter role",
        value: "", // Provide the initial value here
        info: "The practitioner can perform this role at for the organization.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 728,
        label: "service",
        placeholder: "Enter service",
        value: "", // Provide the initial value here
        info: "The list of healthcare services that this worker provides for this role's Organization/Location(s).",
        infobold: "",
        chains: true,
        chipsname: ["service-category", "organization.name", "location.name"],
      }, {
        id: 254,
        label: "specialty",
        placeholder: "Enter specialty",
        value: "", // Provide the initial value here
        info: "The practitioner has this specialty at an organization.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],
    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "429",
        description: "Too many request",
        dotType: "unknown",
      },
      {
        statusCode: "401",
        description: "Unauthorized",
        dotType: "unknown",
      },
    ],
  },
  PractitionerRoleid: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/PractitionerRole/{id}",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory/PractitionerRole/id",
    languageurl:
      "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-PractitionerRole",
    path: [
      {
        id: 130,
        label: "id",
        placeholder: "id",
        value: "",
        info: "Logical id of this artifact.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 131,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 132,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 133,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  AllergyIntolerance: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/AllergyIntolerance",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/AllergyIntolerance",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-allergyintolerance",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 337,
        label: "patient",
        placeholder: "patient",
        value: "", // Provide the initial value here
        info: "",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 437,
        label: "clinical-status",
        placeholder: "clinical-status",
        value: "", // Provide the initial value here
        info: "",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  CarePlan: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/CarePlan",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/CarePlan",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-careplan",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 888,
        label: "category",
        placeholder: "Enter category",
        value: "", // Provide the initial value here
        info: "Type of plan.",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 688,
        label: "date",
        placeholder: "Enter date",
        value: "", // Provide the initial value here
        info: "Time period plan covers.",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 488,
        label: "patient",
        placeholder: "Enter patient",
        value: "", // Provide the initial value here
        info: "Who the care plan is for.",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 388,
        label: "status",
        placeholder: "Enter status",
        value: "", // Provide the initial value here
        info: "draft | active | suspended | completed | entered-in-error | cancelled | unknown",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  CareTeam: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/CareTeam",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/CareTeam",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-careteam",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 337,
        label: "patient",
        placeholder: "Enter patient",
        value: "", // Provide the initial value here
        info: "patient.",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 437,
        label: "status",
        placeholder: "Patient Status",
        value: "", // Provide the initial value here
        info: "patient Status.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Condition: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Condition",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Condition",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-condition",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 828,
        label: "category",
        placeholder: "Enter category",
        value: "", // Provide the initial value here
        info: "The category of the condition",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 728,
        label: "clinical-status",
        placeholder: "Enter clinical-status",
        value: "", // Provide the initial value here
        info: "The clinical status of the condition",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 628,
        label: "patient",
        placeholder: "Enter patient",
        value: "", // Provide the initial value here
        info: "Who has the condition?",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 528,
        label: "onset-date",
        placeholder: "Enter onset-date",
        value: "", // Provide the initial value here
        info: "Date related onsets (dateTime and Period)",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 428,
        label: "code",
        placeholder: "Enter code",
        value: "", // Provide the initial value here
        info: "Code for the condition",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Coverage: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Coverage",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Coverage",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-coverage",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  ExplanationOfBenefit: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/ExplanationOfBenefit",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/ExplanationOfBenefit",
    languageurl:
      "http://hl7.org/fhir/us/carin-bb/StructureDefinition/C4BB-ExplanationOfBenefit",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 237,
        label: "status",
        placeholder: "Enter status",
        value: "", // Provide the initial value here
        info: "Status of the instance",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 337,
        label: "provider",
        placeholder: "Enter provider",
        value: "", // Provide the initial value here
        info: "The name or alias of the provider",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 437,
        label: "service-date",
        placeholder: "Enter service-date",
        value: "", // Provide the initial value here
        info: "The service date for the EOB. It can be ?service-date=le2020-01-01 or ?service-date=ge2020-01-01 or a range ?service-date=ge2019-10-01&service-date=le2020-01-01.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 537,
        label: "type",
        placeholder: "Enter type",
        value: "", // Provide the initial value here
        info: "The category of claim, e.g. oral, pharmacy, vision, institutional, professional.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 637,
        label: "patient",
        placeholder: "Enter patient",
        value: "", // Provide the initial value here
        info: "The party to whom the professional services and/or products have been supplied or are being considered and for whom actual for forecast reimbursement is sought.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 737,
        label: "identifier",
        placeholder: "Enter identifier",
        value: "", // Provide the initial value here
        info: "An identifier for the target resource. This is used when there is no way to reference the other resource directly, either because the entity it represents is not available through a FHIR server, or because there is no way for the author of the resource to convert a known identifier to an actual location. There is no requirement that a Reference.identifier point to something that is actually exposed as a FHIR instance, but it SHALL point to a business concept that would be expected to be exposed as a FHIR instance, and that instance would need to be of a FHIR resource type allowed by the reference.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Goal: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Goal",
    codeurl: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Goal",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-goal",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 666,
        label: "lifecycle-status",
        placeholder: "Enter lifecycle-status",
        value: "", // Provide the initial value here
        info: "proposed | planned | accepted | active | on-hold | completed | cancelled | entered-in-error | rejected",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 766,
        label: "patient",
        placeholder: "Enter patient",
        value: "", // Provide the initial value here
        info: "Who this goal is intended for",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 566,
        label: "target-date",
        placeholder: "Enter target-date",
        value: "", // Provide the initial value here
        info: "Reach goal on or before",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Immunization: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Immunization",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Immunization",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-immunization",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 111,
        label: "patient",
        placeholder: "Enter patient",
        value: "", // Provide the initial value here
        info: "Patient",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 211,
        label: "date",
        placeholder: "Enter date",
        value: "", // Provide the initial value here
        info: "date",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 311,
        label: "status",
        placeholder: "Enter status",
        value: "", // Provide the initial value here
        info: "status",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  MedicationRequest: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/MedicationRequest",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/MedicationRequest",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-medicationrequest",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 321,
        label: "patient",
        placeholder: "Enter patient",
        value: "", // Provide the initial value here
        info: "Returns prescriptions for a specific patient",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 201,
        label: "intent",
        placeholder: "Enter intent",
        value: "", // Provide the initial value here
        info: "Returns prescriptions with different intents",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 301,
        label: "status",
        placeholder: "Enter status",
        value: "", // Provide the initial value here
        info: "Status of the prescription",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 401,
        label: "encounter",
        placeholder: "Enter encounter",
        value: "", // Provide the initial value here
        info: "Return prescriptions with this encounter identifier",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 501,
        label: "authoredon",
        placeholder: "Enter authoredon",
        value: "", // Provide the initial value here
        info: "Return prescriptions written on this date",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Observation: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Observation",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Observation",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-observation",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Patient: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Patient",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Patient",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-patient",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 977,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 777,
        label: "_id",
        placeholder: "Enter _id",
        value: "", // Provide the initial value here
        info: "Logical id of this artifact",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 677,
        label: "birthdate",
        placeholder: "Enter birthdate",
        value: "", // Provide the initial value here
        info: "The patient's date of birth",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 577,
        label: "family",
        placeholder: "Enter family",
        value: "", // Provide the initial value here
        info: "A portion of the family name of the patient",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 477,
        label: "gender",
        placeholder: "Enter gender",
        value: "", // Provide the initial value here
        info: "Gender of the patient",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 377,
        label: "identifier",
        placeholder: "Enter identifier",
        value: "", // Provide the initial value here
        info: "A patient identifier",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      }, {
        id: 277,
        label: "name",
        placeholder: "Enter name",
        value: "", // Provide the initial value here
        info: "A server defined search that may match any of the string fields in the HumanName, including family, give, prefix, suffix, suffix, and/or text",
        infobold: "",
        chains: true,
        chipsname: ["multipleAnd", "multipleOr", "modifier", "comparator", "chain"],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Procedure: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Procedure",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Procedure",
    languageurl:
      "http://hl7.org/fhir/us/core/StructureDefinition/us-core-procedure",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 555,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 455,
        label: "patient",
        placeholder: "Enter patient",
        value: "", // Provide the initial value here
        info: "Patient",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 355,
        label: "date",
        placeholder: "Enter date",
        value: "", // Provide the initial value here
        info: "date",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 655,
        label: "status",
        placeholder: "Enter status",
        value: "", // Provide the initial value here
        info: "status",
        infobold: "",
        chains: false,
        chipsname: [],
      }, {
        id: 755,
        label: "code",
        placeholder: "Enter code",
        value: "", // Provide the initial value here
        info: "code",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  everything: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Patient/{PatientId}/$everything",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/patientaccess/Patient/{PatientId}/$everything",
    languageurl:
      "https://build.fhir.org/ig/HL7/US-Core",
    path: [
      {
        id: 118,
        label: "PatientId",
        placeholder: "PatientId",
        value: "",
        info: "Patient Id.",
        infobold: "",
      },
    ],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
      {
        id: 138,
        label: "Authorization",
        placeholder: "Bearer {your access token}",
        value: "",
        info: "Valid access token",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Metadata: {
    url: "https://api-sandbox.aaneelconnect.com/cms/r4/Metadata",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/cms/r4/Metadata",
    languageurl:
      "https://www.hl7.org/fhir/capabilitystatement.html",
    path: [],
    header: [
      {
        id: 135,
        label: "payer-id",
        placeholder: "payer-id",
        value: "",
        info: "payer-id should be part of either Query Parameter or Header.",
        infobold: "",
      },
      {
        id: 136,
        label: "Accept",
        placeholder: "application/json",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],

    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],

    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Authorize: {
    url: "https://api-sandbox.aaneelconnect.com/AadSmartOnFhirProxy/authorize",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/AadSmartOnFhirProxy/authorize",
    languageurl:
      "https://www.hl7.org/fhir/capabilitystatement.html",
    path: [],
    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 137,
        label: "client_id",
        placeholder: "{client_id}",
        value: "{client_id}", // Provide the initial value here
        info: "The client_id from your registered application.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 137,
        label: "redirect_uri",
        placeholder: "{redirect_uri}",
        value: "{redirect_uri}", // Provide the initial value here
        info: "The callback URL of your application. The user will be directed to this URL after authorizing your application.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 137,
        label: "response_type",
        placeholder: "code",
        value: "code", // Provide the initial value here
        info: "Supported response type: code",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 137,
        label: "scope",
        placeholder: "openid profile email phone offline_access",
        value: "openid profile email phone offline_access", // Provide the initial value here
        info: "FHIR Scope",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 137,
        label: "state",
        placeholder: "{Unique string}",
        value: "{Unique string}", // Provide the initial value here
        info: "Recommended. A random string used to protect against request forgery attacks.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 137,
        label: "code_challenge",
        placeholder: "{code challenge}",
        value: "{code challenge}", // Provide the initial value here
        info: "codechallenge = BASE64URL-ENCODE(SHA256(ASCII(codeverifier)))",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 137,
        label: "code_challenge_method",
        placeholder: "S256",
        value: "S256", // Provide the initial value here
        info: "Supported code_challenge_method: S256",
        infobold: "",
        chains: false,
        chipsname: [],
      },
      {
        id: 137,
        label: "response_mode",
        placeholder: "query",
        value: "query", // Provide the initial value here
        info: "Supported response_mode: query",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],
    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  Token: {
    url: "https://api-sandbox.aaneelconnect.com/AadSmartOnFhirProxy/Token",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/AadSmartOnFhirProxy/Token",
    languageurl:
      "https://www.hl7.org/fhir/capabilitystatement.html",
    path: [],
    header: [
      {
        id: 136,
        label: "Content-Type",
        placeholder: "application/x-www-form-urlencoded",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],
    info: [
      {
        id: 200,
        label: "grant_type",
        placeholder: "placeholder for grant_type",
        value: "", // Provide the initial value here
      }, {
        id: 201,
        label: "code",
        placeholder: "placeholder for code",
        value: "", // Provide the initial value here
      }, {
        id: 202,
        label: "redirect_uri",
        placeholder: "placeholder for redirect_uri",
        value: "", // Provide the initial value here
      }
    ],
    queryParams: [
      {
        id: 147,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],
    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
  RefreshToken: {
    url: "https://api-sandbox.aaneelconnect.com/AadSmartOnFhirProxy/Token",
    codeurl:
      "https://api-sandbox.aaneelconnect.com/AadSmartOnFhirProxy/Token",
    languageurl:
      "https://www.hl7.org/fhir/capabilitystatement.html",
    path: [],
    header: [
      {
        id: 136,
        label: "Content-Type",
        placeholder: "application/x-www-form-urlencoded",
        value: "",
        info: "request HTTP header indicates which content types, expressed as MIME types",
        infobold: "",
      },
    ],
    info: [
      {
        id: 203,
        label: "grant_type",
        placeholder: "placeholder for grant_type",
        value: "", // Provide the initial value here
      }, {
        id: 204,
        label: "refresh_token",
        placeholder: "placeholder for refresh_token",
        value: "", // Provide the initial value here
      }
    ],
    queryParams: [
      {
        id: 137,
        label: "payer-id",
        placeholder: "Enter Id",
        value: "", // Provide the initial value here
        info: "Select Payer ID.",
        infobold: "",
        chains: false,
        chipsname: [],
      },
    ],
    response: [
      {
        statusCode: "200",
        description: "the resource being returned",
        dotType: "good",
      },
      {
        statusCode: "400",
        description: "Bad Request",
        dotType: "bad",
      },
      {
        statusCode: "404",
        description: "Not Found",
        dotType: "unknown",
      },
      {
        statusCode: "Default",
        description: "Error, with details",
        dotType: "unknown",
      },
    ],
  },
};
