export const urls = {
    user: {
      createUser: "/User",
      loginUser:"/Auth/login",
      getUserData:(payerID:any)=>`/User/${payerID}`,
      ForgotPassword:"/User/forgot-password",
      resetPassword:"/User/reset-password",
    },
    registerApplication:{
      registerApp:"/RegisteredApps",
      updateApplication:(appID:any)=>`/RegisteredApps/${appID}`,
      getClientconfigurations:"/api/ClientConfigurations",
      getTemplateById: (id: string) => `/template/get/${id}`,
      getRegisteredApps: (userId: number, pageNumber: number, pageSize: number) => `/RegisteredApps?ConnectUserID=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=createdAt&sortOrder=desc`,
      sendEmail:"/SendEmail?code=GTjthVwbfschPEzidx7jWPzYMYWk-Qud93ybNg2PpRnEAzFuvUdTmQ%3D%3D",
      uploadLogo:"/Upload",
      getApplicationsData:(appID:any)=>`/RegisteredApps/${appID}`
    }
  };
  
  