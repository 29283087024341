import { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Grid,
  IconButton,
  InputAdornment,
  FilledInput,
  FormControl,
  InputLabel,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import "./CardInfoContent.scss";
import { PathParamModel } from "../../_models/PathParamModel";

interface PathInfoProps {
  pathinfo: PathParamModel[];
}

export const PathInfoContent = ({ pathinfo }: PathInfoProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    pathinfo.length > 0 ? pathinfo[0].id : null
  );

  // Initialize inputValues with empty strings for all properties
  const [inputValues, setInputValues] = useState<{
    [key: number]: string;
  }>(
    pathinfo.reduce((acc, curr) => {
      acc[curr.id] = ""; // Initialize with empty string
      return acc;
    }, {} as { [key: number]: string })
  );

  const handleChange = (id: number, value: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));
  };

  const handleListItemClick = (id: number | null) => {
    setSelectedIndex(id);
  };

  const handleClear = (id: number) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [id]: "",
    }));
  };

  return (
    <div>
      <Accordion defaultExpanded className="accordian-content">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ m: 0 }}
          className="accordian-header"
          aria-controls="panel1bh-content"
        >
          Path
        </AccordionSummary>
        <AccordionDetails className="accordian-info">
          <Box>
            <List component="nav" aria-label="main mailbox folders">
              {pathinfo.map((header, index) => (
                <ListItem key={header.id + index} disablePadding>
                  <ListItemButton
                    className="list-info"
                    disableRipple
                    selected={selectedIndex === header.id}
                    onClick={() => handleListItemClick(header.id)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div className="text-header-width">
                          <div className="header-txt">
                            {header.label}
                            <span className="string-info">String</span>
                          </div>
                          <div className="sub-content">
                            {header.info}
                            {""}
                            <span className="bold-txt">{header.infobold}</span>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        display="flex"
                        justifyContent="end"
                        className="custom-controllers"
                      >
                        <FormControl fullWidth variant="filled">
                          <InputLabel
                            htmlFor={`input-with-no-floating-label${header.id}`}
                          >
                            {header.label}
                          </InputLabel>
                          <FilledInput
                            id={`input-with-no-floating-label${header.id}`}
                            placeholder={header.placeholder}
                            value={inputValues[header.id]}
                            onChange={(event) =>
                              handleChange(header.id, event.target.value)
                            }
                            autoComplete="off"
                            // InputLabelProps should be applied on FormControl or TextField if you're using them
                            // InputLabelProps={{ shrink: false }} // This prop is not required here
                            endAdornment={
                              inputValues[header.id] && (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="clear input"
                                    onClick={() => handleClear(header.id)}
                                    edge="end"
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
