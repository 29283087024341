import * as Yup from "yup";
const emailRegex = /^(?!\.)[a-zA-Z0-9._%+-]+(?<!\.)@[a-zA-Z0-9.-]+(?<!\.|-)\.(?!web$)[a-zA-Z]{2,3}$/;

export class Validators {
  static SignupValidator() {
    return Yup.object().shape({
      firstName: Yup.string()
        .required("First name is required")
        .max(50, "First name must not exceed 50 characters"),
      lastName: Yup.string()
        .required("Last name is required")
        .max(50, "Last name must not exceed 50 characters"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(
          emailRegex,
          "Invalid email address"
        )
        .required("Email Address is required"),
     organizationName: Yup.string().required("Organization name is required"),
      passwordHash: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters long"),
      confirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("passwordHash")], "Passwords must match"), // Corrected line
    });
  }

  static LoginValidator() {
    return Yup.object().shape({
      username: Yup.string()
        .email("Invalid email address")
        .matches(
          emailRegex,
          "Invalid email address"
        )
        .required("Email Address is required"),
      password: Yup.string().required("Password is required"),
    });
  }

  static forgotPassword(){
    return Yup.object().shape({ 
      email: Yup.string()
      .email("Invalid email address")
      .matches(
        emailRegex,
        "Invalid email address"
      )
      .required("Email Address is required"),
    })
  }
  static ResetPasswordValidator(){
    return Yup.object().shape({
      newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  })
}

  static RegisterNewApplicationValidator(){
    return Yup.object().shape({
      applicationName: Yup.string().required("Application name is required"),
      callbackURLs: Yup.array()
      .of(
        Yup.string()
          .url("Invalid URL") // Validates each URL
      )
      .min(1, "At least one callback URL is required") // Ensures at least one URL is present
      .required("Callback URLs are compulsory"), // Ensures the array itself is provided
    
      payerID: Yup.number().required("Payer information is compulsory"),
      environment: Yup.string().required("Please select an environment"),
      supportEmail: Yup.string()
  .matches(
    emailRegex,
    "Invalid email address"
  ),
  //.required("Support email is required"),
      supportPhone: Yup.string()
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Phone number must be in the format XXX-XXX-XXXX")
    });
  }
  
}