import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import "./Home.scss";
import { createTheme, ThemeProvider, Container } from "@mui/material";
import Link from "@mui/material/Link";
import BannerImg from "../../images/banner-img.png";
import providerDirectory from "../../images/provider-directory.png";
import formulary from "../../images/formulary.png";
import sandBoxUrl from "../../images/sandbox-icon.png";
import productionUrl from "../../images/production-icon.png";
import logoWhite from "../../images/logo-white.svg";
import logoBlue from "../../images/logo-blue.png";
import healthSun from "../../images/health-sun-logo.svg";
import optimum from "../../images/optimum-logo.svg";
import freedomHealth from "../../images/freedom-logo.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LockIcon from "@mui/icons-material/Lock";
import Grid from "@mui/material/Unstable_Grid2";
import { useDispatch, useSelector } from "react-redux";
import { isTokenExpired, removeToken } from "../../utils/Helper";
import { AppDispatch } from "../../redux/store";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1620,
    },
  },
});

function createData(
  logo: string,
  payername: string,
  sandboxurl: string,
  payerid: string
) {
  return { logo, payername, sandboxurl, payerid };
}

const rows = [
  createData(
    healthSun,
    "Health Sun",
    "32818196de8e4578b46ad2d0869c8b0b",
    "8476cfb7452b4575be4d6609877439a8"
  ),
  createData(
    optimum,
    "Optimum Healthcare",
    "99240C0D3F544501812F97B9D1BCE67D",
    "F87CDF9999264C81A948DC2EEA63C17D"
  ),
  createData(
    freedomHealth,
    "Freedom Health Plan",
    "E06B7F1BBE7D4E93AFB75F2C9928CC8D",
    "07E34CBA1C2044EC84518E2F42CCFC4D"
  ),
];

const Home: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const userInfo = useSelector((state: any) => state?.user.userData);
  const [firstName, setFirstName] = useState(
    localStorage.getItem("firstName") || ""
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem("lastName") || ""
  );
  const open = Boolean(anchorEl);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const scrollToElement = (element: HTMLElement | null) => {
    if (!element) return;
    const elementTop = element.getBoundingClientRect().top + window.scrollY;
    let scrollStep = (elementTop - window.scrollY) / (500 / 15), // 500ms duration, 15ms interval
      scrollInterval = setInterval(() => {
        if (
          window.scrollY < elementTop &&
          window.scrollY + window.innerHeight < document.body.scrollHeight
        ) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
  };
  const scrollToTopSmoothly = () => {
    let scrollStep = -window.scrollY / (500 / 15), // 500ms duration, 15ms interval
      scrollInterval = setInterval(() => {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
  };
  const handleGetStartedClick = () => {
    const targetElement = document.getElementById("target-getstarted");
    scrollToElement(targetElement);
  };
  const handleHomeClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (isAtTop) {
      event.preventDefault();
    } else {
      event.preventDefault();
      scrollToTopSmoothly();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
      setScroll(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    // Initial check
    handleScroll();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkAuth = () => {
      if (isTokenExpired()) {
        removeToken();
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);
  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(!isLoggedIn);
    handleClose();
    window.location.href = "/home";
  };

  const firstNameInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
  const lastNameInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
  const userInitials = `${firstNameInitial}${lastNameInitial}`;
  return (
    <>
      <main className={showNavbar ? "overflow-hidden" : ""}>
        <header className={scroll ? "header-sticky" : ""}>
          <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
              <div className="header-box">
                {scroll ? (
                  <Link href="#">
                    <img src={logoBlue} alt="AaNeel Connect" />
                    <Typography className="version-info-blue">V 0.1</Typography>
                  </Link>
                ) : (
                  <Link href="#">
                    <img src={logoWhite} alt="AaNeel Connect" />
                    <Typography className="version-info-white">
                      V 0.1
                    </Typography>
                  </Link>
                )}
                <div className="menu-icon" onClick={handleShowNavbar}>
                  <MenuIcon />
                </div>
                <div
                  className={`overlay ${showNavbar && "overlay-active"}`}
                  onClick={handleShowNavbar}
                ></div>
                <div className={`menu nav-elements  ${showNavbar && "active"}`}>
                  <div className="menu-logo">
                    <Link href="/providerdirectory/location-api/location">
                      <img src={logoBlue} alt="AaNeel Connect" />
                    </Link>
                    <CloseIcon onClick={handleShowNavbar} />
                  </div>
                  <ul>
                    <li>
                      <Link
                        href="/home"
                        className={`active ${isAtTop ? "text-only" : ""}`}
                        onClick={handleHomeClick}
                      >
                        Home
                      </Link>
                    </li>
                    {isLoggedIn && (
                      <li>
                        <Link href="/Dashboard">Dashboard</Link>
                      </li>
                    )}
                    <li
                      onClick={() =>
                        navigate("/providerdirectory/location-api/location")
                      }
                    >
                      <Link sx={{ cursor: "pointer" }}>API Documentation </Link>
                    </li>
                    <li>
                      {isLoggedIn ? (
                        <>
                          <Button
                            className="user-btn  home-page"
                            id="demo-positioned-button"
                            aria-controls={
                              open ? "demo-positioned-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            ref={buttonRef}
                          >
                            <div className="list-info">
                              <div className="welcome-info">Welcome back</div>
                              <div className="user-info">
                                {firstName && firstName + " " + lastName}
                              </div>
                            </div>
                            <div className="user-letter">{userInitials}</div>
                          </Button>
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            className="user-profile-dropdown"
                            open={open}
                            onClose={handleClose}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "center",
                              vertical: "bottom",
                            }}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: 200,
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                // mt: 1.5,
                                // "& .MuiAvatar-root": {
                                //   width: 32,
                                //   height: 32,
                                //   ml: -0.5,
                                //   mr: 1,
                                // },
                                // "&::before": {
                                //   content: '""',
                                //   display: "block",
                                //   position: "absolute",
                                //   top: 0,
                                //   right: 14,
                                //   width: 10,
                                //   height: 10,
                                //   bgcolor: "background.paper",
                                //   transform: "translateY(-50%) rotate(45deg)",
                                //   zIndex: 0,
                                // },
                              },
                            }}
                          >
                            <MenuItem
                              onClick={handleLogout}
                              className="logout-menu"
                            >
                              Logout
                              <i className="an_icons">ac_logout</i>
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <Button className="sign-in" href="/userLogin">
                          <i className="an_icons">ac_lock_fill</i>
                          Sign in
                        </Button>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </Container>
          </ThemeProvider>
        </header>
        <section className="banner-section">
          <div className="banner-overlap">
            <ThemeProvider theme={theme}>
              <Container maxWidth="xl">
                <Grid
                  container
                  sx={{
                    alignItems: { lg: "flex-end", md: "flex-start" },
                    flexDirection: { md: "row", xs: "column" },
                  }}
                >
                  <Grid
                    lg={6}
                    md={6}
                    sx={{ order: { md: 1, xs: 2 }, mt: { md: 0, xs: 6 } }}
                  >
                    <div className="banner-content">
                      <h1>CMS Interoperability: APIs for Data Exchange</h1>
                      <p>
                        Driving interoperability and patient access to health
                        information by liberating patient data using CMS
                        authority.
                      </p>
                      <div className="banner-btns">
                        <Button
                          className="btn btn-pink"
                          onClick={handleGetStartedClick}
                        >
                          Get Started - It’s Free
                        </Button>
                        <Button
                          className="btn btn-blue"
                          onClick={() =>
                            navigate("/providerdirectory/location-api/location")
                          }
                          //href="/providerdirectory/location-api/location"
                        >
                          API Documentation
                        </Button>
                      </div>
                    </div>
                  </Grid>
                  <Grid lg={6} md={6} sx={{ order: { md: 2, xs: 1 } }}>
                    <div className="banner-image">
                      <img src={BannerImg} alt="Banner" />
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </ThemeProvider>
          </div>
        </section>
        <section className="about-section">
          <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
              <Grid container direction="row" alignItems="flex-end">
                <Grid lg={6} md={12} mdOffset={6}>
                  <div className="title-main">
                    <small className="blue">API OVERVIEW</small>
                    <h2 className="title-xl">About AaNeel Connect API</h2>
                  </div>
                  <div className="about-content">
                    <div>
                      <h3 className="title-md">Overview of the Product</h3>
                      <p>
                        Application Programming Interfaces (APIs) to improve the
                        electronic exchange of healthcare data—sharing
                        information with patients or exchanging information
                        between a payer and provider or between two payers.
                      </p>
                    </div>
                    <div>
                      <h3 className="title-md">Product Description</h3>
                      <Grid container spacing={4}>
                        <Grid xs={12}>
                          <p>
                            In compliance with the CMS Interoperability and
                            Patient Access final rule (CMS-9115-F), payers are
                            mandated to provide access to provider information.
                            The APIs outlined here are necessary for directly
                            retrieving provider information.
                          </p>
                        </Grid>
                      </Grid>
                      <p>
                        Additional information about this API can be found in
                        the Capability Statement API and the swagger document.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </ThemeProvider>
        </section>

        <section className="getstart-section" id="target-getstarted">
          <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
              <div className="title-main">
                <small className="purple">IT’S FREE</small>
                <h2 className="title-xl">Let’s Get Started</h2>
              </div>

              <div className="getstarted-box">
                <Grid container>
                  <Grid
                    lg={3}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      pr: { xl: 8, lg: 6 },
                    }}
                  >
                    <div className="get-start-content">
                      <p>
                        As per regulations, Provider Directory APIs are provided
                        without authentication requirements. After confirming
                        connectivity with the sandbox, utilize the details below
                        to connect to our Production APIs:
                      </p>
                      <p>
                        The URL for our Provider Directory Production endpoints
                        follows this format, where APINAME is substituted with
                        the API name you intend to call
                      </p>
                    </div>
                  </Grid>
                  <Grid lg={9} xs={12}>
                    <div className="box-main">
                      <Box component="section" className="box-purple">
                        <div className="box-header">
                          <div className="box-header-left">
                            <h4 className="title-lg">Provider Directory</h4>
                            <p>
                              Resources related to providers encompass details
                              about insurance plans, locations, healthcare
                              services, and networks essential for disseminating
                              provider directory information. This information
                              also encompasses details about pharmacies.
                            </p>
                          </div>
                          <span>
                            <img
                              src={providerDirectory}
                              alt="Provider Directory"
                            />
                          </span>
                        </div>
                        <div className="box-content">
                          <Grid container spacing={4}>
                            <Grid lg={6} xs={12}>
                              <Link
                                className="box-link"
                                onClick={() =>
                                  navigate(
                                    "/providerdirectory/location-api/location"
                                  )
                                }
                              >
                                <div className="box-link-left">
                                  <span>
                                    <img src={sandBoxUrl} alt="Sandbox URL" />
                                  </span>
                                  <div>
                                    <h5 className="title-sm">Sandbox URL</h5>
                                    <p>
                                      https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory
                                    </p>
                                  </div>
                                </div>
                                <div className="box-link-right">
                                  <Link sx={{ border: "none !important" }}>
                                    <span>
                                      <>
                                        {" "}
                                        <CallMadeOutlinedIcon />
                                      </>
                                    </span>
                                  </Link>
                                </div>
                              </Link>
                            </Grid>
                            <Grid lg={6} xs={12}>
                              <Link
                                className="box-link"
                                onClick={() =>
                                  navigate(
                                    "/providerdirectory/location-api/location/prod"
                                  )
                                }
                              >
                                <div className="box-link-left">
                                  <span>
                                    <img
                                      src={productionUrl}
                                      alt="Production URL"
                                    />
                                  </span>
                                  <div>
                                    <h5 className="title-sm">Production URL</h5>
                                    <p>
                                      https://api.aaneelconnect.com/cms/r4/providerdirectory
                                    </p>
                                  </div>
                                </div>
                                <div className="box-link-right">
                                  <Link sx={{ border: "none !important" }}>
                                    <span>
                                      <>
                                        {" "}
                                        <CallMadeOutlinedIcon />
                                      </>
                                    </span>
                                  </Link>
                                </div>
                              </Link>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box component="section" className="box-blue">
                        <div className="box-header">
                          <div className="box-header-left">
                            <h4 className="title-lg">Formulary</h4>
                            <p>
                              The two resources within this category have the
                              capability to furnish comprehensive payer
                              formulary details. Utilizing the Who Am I endpoint
                              for coverage information enables the retrieval of
                              specific member plan formulary details.
                            </p>
                          </div>
                          <span>
                            <img src={formulary} alt="Formulary" />
                          </span>
                        </div>
                        <div className="box-content">
                          <Grid container spacing={4}>
                            <Grid lg={6} xs={12}>
                              <Link
                                className="box-link"
                                onClick={() =>
                                  navigate("/formulary/list-api/list")
                                }
                              >
                                <div className="box-link-left">
                                  <span>
                                    <img src={sandBoxUrl} alt="Sandbox URL" />
                                  </span>
                                  <div>
                                    <h5 className="title-sm">Sandbox URL</h5>
                                    <p>
                                      https://api-sandbox.aaneelconnect.com/cms/r4/formulary
                                    </p>
                                  </div>
                                </div>
                                <div className="box-link-right">
                                  <Link sx={{ border: "none !important" }}>
                                    <span>
                                      <>
                                        {" "}
                                        <CallMadeOutlinedIcon />
                                      </>
                                    </span>
                                  </Link>
                                </div>
                              </Link>
                            </Grid>
                            <Grid lg={6} xs={12}>
                              <Link
                                className="box-link"
                                onClick={() =>
                                  navigate("/formulary/list-api/list/prod")
                                }
                              >
                                <div className="box-link-left">
                                  <span>
                                    <img
                                      src={productionUrl}
                                      alt="Production URL"
                                    />
                                  </span>
                                  <div>
                                    <h5 className="title-sm">Production URL</h5>
                                    <p>
                                      https://api.aaneelconnect.com/cms/r4/formulary
                                    </p>
                                  </div>
                                </div>
                                <div className="box-link-right">
                                  <Link sx={{ border: "none !important" }}>
                                    <span>
                                      <>
                                        {" "}
                                        <CallMadeOutlinedIcon />
                                      </>
                                    </span>
                                  </Link>
                                </div>
                              </Link>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </ThemeProvider>
        </section>

        <section className="payer-section">
          <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
              <div className="payer-box">
                <Grid container>
                  <Grid
                    lg={3}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      pr: { xl: 8, lg: 6 },
                    }}
                  >
                    <div className="payer-content">
                      <h4 className="title-lg">Payer ID’s</h4>
                      <p>
                        Crucially, our Provider Directory APIs necessitate the
                        inclusion of the CHC Payer ID parameter. In the example,
                        CHCPAYERID should be replaced with the payer’s ID you
                        wish to access (refer to the table for a complete list
                        of Sandbox and Production Payer IDs)
                      </p>
                    </div>
                  </Grid>
                  <Grid lg={9} xs={12}>
                    <div className="payer-view">
                      <div className="payer-grid-view">
                        <div className="payer-table">
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Payer</TableCell>
                                  <TableCell>Sandbox</TableCell>
                                  <TableCell>Payer ID (Production)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows.map((row) => (
                                  <TableRow
                                    key={row.payername}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      <div className="payer-column">
                                        <span>
                                          <img
                                            src={row.logo}
                                            alt={row.payername}
                                          />
                                        </span>
                                        <label>{row.payername}</label>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        navigate(
                                          "/providerdirectory/location-api/location",{
                                            state:{route:row.payername+"sandbox"}
                                          }
                                        )
                                      }
                                    >
                                      <Link>
                                        {row.sandboxurl.toLowerCase()}
                                        <CallMadeOutlinedIcon />
                                      </Link>
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        navigate(
                                          "/providerdirectory/location-api/location/prod",{
                                            state:{route:row.payername+"production"}
                                          }
                                        )
                                      }
                                    >
                                      <Link>
                                        {row.payerid.toLowerCase()}
                                        <CallMadeOutlinedIcon />
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </ThemeProvider>
        </section>

        <footer>
          <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
              <Grid container className="bt-1" sx={{ py: { xl: 7, xs: 4 } }}>
                <Grid
                  lg={3}
                  xs={12}
                  className="footer-left"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Link
                    href="#"
                    sx={{ alignSelf: "flex-start", mb: 2.5, display: "flex" }}
                  >
                    <img src={logoBlue} alt="AaNeel Connect" />
                  </Link>
                  <span>© 2024 AaNeel Infotech, LLC.</span>
                </Grid>
                <Grid
                  lg={9}
                  xs={12}
                  className="footer-right"
                  sx={{ mt: { xl: 0.5 } }}
                >
                  <div>
                    <Grid container>
                      <Grid
                        lg={3}
                        md={4}
                        xs={6}
                        sx={{
                          order: { lg: 1, md: 3, xs: 4 },
                          mt: { xs: 1, md: 0 },
                        }}
                      >
                        <ul>
                          <li>
                            <span>
                              <PhoneIcon /> Sales:{" "}
                              <Link
                                href="tel:+1 (813) 909-9555"
                                sx={{ ml: 0.5 }}
                              >
                                +1 (813) 909-9555
                              </Link>
                            </span>
                          </li>
                          <li>
                            <span>
                              <MailIcon />{" "}
                              <Link href="mailto:Sales@aaneel.com">
                                Sales@aaneel.com
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid
                        lg={2}
                        md={4}
                        xs={6}
                        sx={{ order: { lg: 2, md: 2, xs: 2 } }}
                      >
                        <address>
                          6650 Gunn Hwy, <br /> Tampa, FL 33625
                        </address>
                      </Grid>
                      <Grid
                        lg={2}
                        md={4}
                        xs={6}
                        sx={{ order: { lg: 3, md: 1, xs: 1 } }}
                      >
                        <ul>
                          <li>
                            <Link href="/home">Home</Link>
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                "/providerdirectory/location-api/location"
                              )
                            }
                          >
                            <Link>AaNeel Connect API</Link>
                          </li>
                        </ul>
                      </Grid>
                      <Grid
                        lg={2}
                        md={4}
                        xs={6}
                        sx={{
                          order: { lg: 4, md: 4, xs: 3 },
                          mt: { xs: 1, md: 0 },
                        }}
                      >
                        <ul>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                "/providerdirectory/endpoint-api/endpoint"
                              )
                            }
                          >
                            <Link>Provider</Link>
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/formulary/list-api/list")}
                          >
                            <Link>Directory Formulary</Link>
                          </li>
                        </ul>
                      </Grid>
                      <Grid
                        lg={3}
                        md={4}
                        xs={6}
                        sx={{
                          order: { xs: 5 },
                          display: "flex",
                          justifyContent: {
                            lg: "flex-end",
                            md: "flex-start",
                          },
                          alignItems: { lg: "flex-start", md: "flex-end" },
                          mt: { xs: 1, md: 0 },
                        }}
                        className="social-box"
                      >
                        <div className="social-icons">
                          <Link
                            className="linkedin"
                            href="https://www.linkedin.com/company/aaneel-usa/mycompany/"
                            target="_blank"
                          >
                            <LinkedInIcon />
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid xs={12} className="copy-right">
                  <span>© 2024 AaNeel Infotech, LLC.</span>
                </Grid>
              </Grid>
            </Container>
          </ThemeProvider>
        </footer>
      </main>
    </>
  );
};

export default Home;
