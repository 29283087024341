import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CardInfoContent } from "../components/content/CardInfoContent";
import Home from "../components/home/Home";
import PatientAPIAccess from "../components/PatientAPI/PatientAPIAccess";
import { Layout } from "../components/layout/Layout";
import UserRegister from "../components/users/userRegistration";
import UserLogin from "../components/users/userLogin";
import ForgotPassword from "../components/users/forgetPassword";
import ResetPassword from "../components/users/resetPassword";

// const ProfileInfo = lazy(
//   () => import("../components/Secure/pages/profileInfo")
// );
const RegisterNewApplication = lazy(
  () =>
    import(
      "../components/Secure/pages/RegisterNewApplication/RegisterNewApplication"
    )
);
const Dashboard = lazy(
  () => import("../components/Secure/pages/Dashboard/Dashboard")
);
const DetailsPage = lazy(
  () => import("../components/DashboardComponents/DetailsPage")
);
const AuthGuard = lazy(() => import("../service/AuthGuard"));
const Loader = lazy(() => import("../components/ui-elements/Loader"));

const App = () => {
  const publicRoutes = [
    { path: "/home", element: <Home /> },
    { path: "/PatientAPIAccess", element: <PatientAPIAccess /> },
    { path: "/UserLogin", element: <UserLogin /> },
    { path: "/ForgotPassword", element: <ForgotPassword /> },
    { path: "/UserRegister", element: <UserRegister /> },
    { path: "/ResetPassword", element: <ResetPassword /> },
  ];

  const privateRoutes = [
    // { path: "/ProfileInfo", element: <ProfileInfo /> },
    { path: "/RegisterNewApplication", element: <RegisterNewApplication /> },
    { path: "/Dashboard", element: <Dashboard /> },
    { path: "/Details", element: <DetailsPage /> },
  ];

  const cardInfoRoutes = [
    {
      path: "/providerdirectory/location-api/location/:environment?",
      id: "location",
    },
    {
      path: "/providerdirectory/location-api/locationid/:environment?",
      id: "locationid",
    },
    {
      path: "/providerdirectory/healthcareService-api/healthcareServiceid/:environment?",
      id: "healthcareid",
    },
    {
      path: "/providerdirectory/healthcareService-api/healthcareService/:environment?",
      id: "healthcare",
    },
    {
      path: "/providerdirectory/InsurancePlan-api/insuranceplan/:environment?",
      id: "insurance",
    },
    {
      path: "/providerdirectory/InsurancePlan-api/insuranceplanid/:environment?",
      id: "insuranceid",
    },
    {
      path: "/providerdirectory/network-api/network/:environment?",
      id: "network",
    },
    {
      path: "/providerdirectory/organization-api/organization/:environment?",
      id: "organization",
    },
    {
      path: "/providerdirectory/organization-api/organizationid/:environment?",
      id: "organizationid",
    },
    {
      path: "/providerdirectory/network-api/networkid/:environment?",
      id: "networkid",
    },
    {
      path: "/providerdirectory/endpoint-api/endpoint/:environment?",
      id: "endpoint",
    },
    {
      path: "/providerdirectory/endpoint-api/endpointid/:environment?",
      id: "endpointid",
    },
    {
      path: "/providerdirectory/practitioner-api/practitioner/:environment?",
      id: "Practitioner",
    },
    {
      path: "/providerdirectory/practitioner-api/practitionerid/:environment?",
      id: "Practitionerid",
    },
    {
      path: "/providerdirectory/practitionerrole-api/practitionerrole/:environment?",
      id: "PractitionerRole",
    },
    {
      path: "/providerdirectory/practitionerrole-api/practitionerroleid/:environment?",
      id: "PractitionerRoleid",
    },
    {
      path: "/providerdirectory/organizationaffiliation-api/organizationaffiliation/:environment?",
      id: "OrganizationAffiliation",
    },
    {
      path: "/providerdirectory/organizationaffiliation-api/organizationaffiliationid/:environment?",
      id: "OrganizationAffiliationid",
    },
    {
      path: "/PatientAccess/AllergyIntolerance/:environment?",
      id: "AllergyIntolerance",
    },
    { path: "/formulary/list-api/list/:environment?", id: "list" },
    { path: "/formulary/list-api/listid/:environment?", id: "listid" },
    {
      path: "/formulary/medicationknowledge-api/medicationknowledge/:environment?",
      id: "medicationknowledge",
    },
    {
      path: "/formulary/medicationknowledge-api/medicationknowledgeid/:environment?",
      id: "medicationknowledgeid",
    },
    {
      path: "/formulary/substance-api/substance/:environment?",
      id: "substance",
    },
    {
      path: "/formulary/substance-api/substanceid/:environment?",
      id: "substanceid",
    },

    { path: "/PatientAccess/CarePlan/:environment?", id: "CarePlan" },
    { path: "/PatientAccess/CareTeam/:environment?", id: "CareTeam" },
    { path: "/PatientAccess/Condition/:environment?", id: "Condition" },
    { path: "/PatientAccess/Coverage/:environment?", id: "Coverage" },
    {
      path: "/PatientAccess/ExplanationOfBenefit/:environment?",
      id: "ExplanationOfBenefit",
    },
    { path: "/PatientAccess/Goal/:environment?", id: "Goal" },
    { path: "/PatientAccess/Immunization/:environment?", id: "Immunization" },
    {
      path: "/PatientAccess/MedicationRequest/:environment?",
      id: "MedicationRequest",
    },
    { path: "/PatientAccess/Observation/:environment?", id: "Observation" },
    { path: "/PatientAccess/Patient/:environment?", id: "Patient" },
    { path: "/PatientAccess/Procedure/:environment?", id: "Procedure" },
    { path: "/Payer-to-Payer/everything/:environment?", id: "everything" },
    { path: "/CapabilityStatement/Metadata/:environment?", id: "Metadata" },
    { path: "/Authorization/Authorize/:environment?", id: "Authorize" },
    { path: "/Authorization/Token/:environment?", id: "Token" },
    { path: "/Authorization/RefreshToken/:environment?", id: "RefreshToken" },
  ];

  return (
    <Router>
      <Routes>
        {/* Redirect from root to home */}
        <Route path="/" element={<Home />} />

        {/* Public Routes */}
        {publicRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}

        {/* Layout with Card Info Routes */}
        <Route path="/" element={<Layout />}>
          {cardInfoRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<CardInfoContent id={route.id} />}
            />
          ))}
          <Route path="/home" element={<CardInfoContent id="provider" />} />

          {/* Private Routes - Including Dashboard */}
          <Route
            path="/Dashboard"
            element={
              <Suspense fallback={<Loader />}>
                <AuthGuard>
                  <Dashboard />
                </AuthGuard>
              </Suspense>
            }
          />
          {privateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <AuthGuard>{route.element}</AuthGuard>
                </Suspense>
              }
            />
          ))}
        </Route>

        {/* Catch all other routes */}
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </Router>
  );
};

export default App;
