export const LeftPanelData = [
  {
    parent: "Provider Directory",
    primary: "Providerdirectory ",
    openState: false,
    id: "",
    path: "",
    secondpath: "",
    children: [
      {
        primarynested: "Endpoint API",
        openState: false,
        id: "Endpoint/{id}",
        subiteminfo: "Endpoint",
        path: "/providerdirectory/endpoint-api/endpoint",
        secondpath: "/providerdirectory/endpoint-api/endpointid",
      },
      {
        primarynested: "Healthcare Service API",
        openState: false,
        id: "HealthcareService/{id}",
        subiteminfo: "Healthcare",
        path: "/providerdirectory/healthcareService-api/healthcareService",
        secondpath:
          "/providerdirectory/healthcareService-api/healthcareServiceid",
      },
      {
        primarynested: "Insurance Plan API",
        openState: false,
        subiteminfo: "Insurance",
        id: "InsurancePlan/{id}",
        path: "/providerdirectory/insuranceplan-api/insuranceplan",
        secondpath: "/providerdirectory/insuranceplan-api/insuranceplanid",
      },
      {
        primarynested: "Location API",
        openState: false,
        subiteminfo: "Location",
        id: "Location/{id}",
        path: "/providerdirectory/location-api/location",
        secondpath: "/providerdirectory/location-api/locationid",
      },
      // {
      //   primarynested: "Network API",
      //   openState: false,
      //   subiteminfo: "Network",
      //   id: "Network/{id}",
      //   path: "/providerdirectory/network-api/network",
      //   secondpath: "/providerdirectory/network-api/networkid",
      // },
      {
        primarynested: "Organization API",
        openState: false,
        subiteminfo: "Organization",
        id: "Organization/{id}",
        path: "/providerdirectory/organization-api/organization",
        secondpath: "/providerdirectory/organization-api/organizationid",
      },
      {
        primarynested: "Organization Affiliation API",
        openState: false,
        subiteminfo: "OrganizationAffiliation",
        id: "OrganizationAffiliation/{id}",
        path: "/providerdirectory/organizationaffiliation-api/organizationaffiliation",
        secondpath:
          "/providerdirectory/organizationaffiliation-api/organizationaffiliationid",
      },
      {
        primarynested: "Practitioner API",
        openState: false,
        subiteminfo: "Practitioner",
        id: "Practitioner/{id}",
        path: "/providerdirectory/practitioner-api/practitioner",
        secondpath: "/providerdirectory/practitioner-api/practitionerid",
      },
      {
        primarynested: "Practitioner Role API",
        openState: false,
        subiteminfo: "PractitionerRole",
        id: "PractitionerRole/{id}",
        path: "/providerdirectory/practitionerrole-api/practitionerrole",
        secondpath:
          "/providerdirectory/practitionerrole-api/practitionerroleid",
      },
    ],
  },
  {
    parent: "Formulary",
    primary: "Formulary",
    openState: false,
    id: "",
    path: "",
    secondpath: "",
    children: [
      {
        primarynested: "List API",
        openState: false,
        subiteminfo: "List",
        id: "list/{id}",
        path: "/formulary/list-api/list",
        secondpath: "/formulary/list-api/listid",
      },
      {
        primarynested: "Substance API",
        openState: false,
        subiteminfo: "Substance",
        id: "Substance/{id}",
        path: "/formulary/substance-api/substance",
        secondpath: "/formulary/substance-api/substanceid",
      },
      {
        primarynested: "Medication Knowledge API",
        openState: false,
        subiteminfo: "MedicationKnowledge",
        id: "MedicationKnowledge/{id}",
        path: "/formulary/medicationknowledge-api/medicationknowledge",
        secondpath: "/formulary/medicationknowledge-api/medicationknowledgeid",
      },
    ],
  },
  {
    parent: "Patient API Access",
    primary: "Patient API Access",
    openState: false,
    id: "",
    path: "",
    secondpath: "",
    children: [
      {
        primarynested: "AllergyIntolerance",
        openState: false,
        subiteminfo: "AllergyIntolerance",
        id: "AllergyIntolerance",
        path: "/PatientAccess/AllergyIntolerance",
        secondpath: "",
      },
      {
        primarynested: "CarePlan",
        openState: false,
        subiteminfo: "CarePlan",
        id: "CarePlan",
        path: "/PatientAccess/CarePlan",
        secondpath: "",
      },
      {
        primarynested: "CareTeam",
        openState: false,
        subiteminfo: "CareTeam",
        id: "CareTeam",
        path: "/PatientAccess/CareTeam",
        secondpath: "",
      },
      {
        primarynested: "Condition",
        openState: false,
        subiteminfo: "Condition",
        id: "Condition",
        path: "/PatientAccess/Condition",
        secondpath: "",
      },
      {
        primarynested: "Coverage",
        openState: false,
        subiteminfo: "Coverage",
        id: "Coverage",
        path: "/PatientAccess/Coverage",
        secondpath: "",
      },
      {
        primarynested: "Explanation Of Benefit",
        openState: false,
        subiteminfo: "ExplanationOfBenefit",
        id: "ExplanationOfBenefit",
        path: "/PatientAccess/ExplanationOfBenefit",
        secondpath: "",
      },
      {
        primarynested: "Goal",
        openState: false,
        subiteminfo: "Goal",
        id: "Goal",
        path: "/PatientAccess/Goal",
        secondpath: "",
      },
      {
        primarynested: "Immunization",
        openState: false,
        subiteminfo: "Immunization",
        id: "Immunization",
        path: "/PatientAccess/Immunization",
        secondpath: "",
      },
      {
        primarynested: "MedicationRequest",
        openState: false,
        subiteminfo: "MedicationRequest",
        id: "MedicationRequest",
        path: "/PatientAccess/MedicationRequest",
        secondpath: "",
      },
      {
        primarynested: "Observation",
        openState: false,
        subiteminfo: "Observation",
        id: "Observation",
        path: "/PatientAccess/Observation",
        secondpath: "",
      },
      {
        primarynested: "Patient",
        openState: false,
        subiteminfo: "Patient",
        id: "Patient",
        path: "/PatientAccess/Patient",
        secondpath: "",
      },
      {
        primarynested: "Procedure",
        openState: false,
        subiteminfo: "Procedure",
        id: "Procedure",
        path: "/PatientAccess/Procedure",
        secondpath: "",
      },
    ],
  },
  {
    parent: "Payer to Payer API",
    primary: "Payer-to-Payer",
    openState: false,
    id: "",
    path: "",
    secondpath: "",
    children: [
      {
        primarynested: "$everything",
        openState: false,
        subiteminfo: "$everything",
        id: "everything",
        path: "/Payer-to-Payer/everything",
        secondpath: "",
      },
    ],
  },
  {
    parent: "Capability Statement",
    primary: "CapabilityStatement",
    openState: false,
    id: "",
    path: "",
    secondpath: "",
    children: [
      {
        primarynested: "Metadata",
        openState: false,
        subiteminfo: "Metadata",
        id: "Metadata",
        path: "/CapabilityStatement/Metadata",
        secondpath: "",
      },
    ],
  },
  {
    parent: "Authorization",
    primary: "Authorization",
    openState: false,
    id: "",
    path: "",
    secondpath: "",
    children: [
      {
        primarynested: "Authorize",
        openState: false,
        subiteminfo: "Authorize",
        id: "Authorize",
        path: "/Authorization/Authorize",
        secondpath: "",
      },
      {
        primarynested: "Token",
        openState: false,
        subiteminfo: "Token",
        id: "Token",
        path: "/Authorization/Token",
        secondpath: "",
      },
      {
        primarynested: "Refresh Token",
        openState: false,
        subiteminfo: "RefreshToken",
        id: "RefreshToken",
        path: "/Authorization/RefreshToken",
        secondpath: "",
      },
    ],
  },
];
